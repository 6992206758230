import React from 'react';
import './Landing_01.css';

export const LandingOne = () => {
  return (
    <>

    <section className='container-landing01'>

      <div className='sub-container-text'>

        <div className='first-text'>Providing exceptional digital experiences through coding and design, I help businesses stand out and connect deeply with their audience.</div>


          <div className='second-text'> Fueled by a passion for design and coding, I deliver a unique edge in creating impactful web2 & web3 experiences.</div>

      </div>

      <div className='sub-container-btn'>


      <div className='second-text-mobile'> Fueled by a passion for design and coding, I deliver a unique edge in creating impactful web2 & web3 experiences.</div>

      <a href="/about" className='btn-aboutme'> About me </a>

      </div>
      
 
        

    </section>

    </>
  )
}

export default LandingOne;