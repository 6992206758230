import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

const FluidSphere = () => {

  useEffect(() => {
    // Access the external DOM element
    const container = document.getElementById("myScene");
    // Scene setup
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 8, 15);
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    container.appendChild(renderer.domElement);

    renderer.setSize(container.clientWidth, container.clientHeight);


    // Shader material
    const material = new THREE.ShaderMaterial({
      vertexShader: `
        varying vec2 vUv;
        varying float displacement;
        uniform float time, pointscale, decay, complex, waves, eqcolor, mouseX, mouseY, hoverIntensity;
  
        vec3 mod289(vec3 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
        vec4 mod289(vec4 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
        vec4 permute(vec4 x) { return mod289(((x * 34.0) + 1.0) * x); }
        vec4 taylorInvSqrt(vec4 r) { return 1.79284291400159 - 0.85373472095314 * r; }
        vec3 fade(vec3 t) { return t * t * t * (t * (t * 6.0 - 15.0) + 10.0); }
  
        float cnoise(vec3 P) {
          vec3 Pi0 = floor(P); vec3 Pi1 = Pi0 + vec3(1.0); Pi0 = mod289(Pi0); Pi1 = mod289(Pi1);
          vec3 Pf0 = fract(P); vec3 Pf1 = Pf0 - vec3(1.0);
          vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
          vec4 iy = vec4(Pi0.yy, Pi1.yy); vec4 iz0 = Pi0.zzzz; vec4 iz1 = Pi1.zzzz;
          vec4 ixy = permute(permute(ix) + iy); vec4 ixy0 = permute(ixy + iz0); vec4 ixy1 = permute(ixy + iz1);
          vec4 gx0 = ixy0 * (1.0 / 7.0); vec4 gy0 = fract(floor(gx0) * (1.0 / 7.0)) - 0.5;
          gx0 = fract(gx0); vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
          vec4 sz0 = step(gz0, vec4(0.0)); gx0 -= sz0 * (step(0.0, gx0) - 0.5);
          gy0 -= sz0 * (step(0.0, gy0) - 0.5);
          vec4 gx1 = ixy1 * (1.0 / 7.0); vec4 gy1 = fract(floor(gx1) * (1.0 / 7.0)) - 0.5;
          gx1 = fract(gx1); vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
          vec4 sz1 = step(gz1, vec4(0.0)); gx1 -= sz1 * (step(0.0, gx1) - 0.5);
          gy1 -= sz1 * (step(0.0, gy1) - 0.5);
          vec3 g000 = vec3(gx0.x, gy0.x, gz0.x); vec3 g100 = vec3(gx0.y, gy0.y, gz0.y);
          vec3 g010 = vec3(gx0.z, gy0.z, gz0.z); vec3 g110 = vec3(gx0.w, gy0.w, gz0.w);
          vec3 g001 = vec3(gx1.x, gy1.x, gz1.x); vec3 g101 = vec3(gx1.y, gy1.y, gz1.y);
          vec3 g011 = vec3(gx1.z, gy1.z, gz1.z); vec3 g111 = vec3(gx1.w, gy1.w, gz1.w);
          vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
          g000 *= norm0.x; g010 *= norm0.y; g100 *= norm0.z; g110 *= norm0.w;
          vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
          g001 *= norm1.x; g011 *= norm1.y; g101 *= norm1.z; g111 *= norm1.w;
          float n000 = dot(g000, Pf0); float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
          float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z)); float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
          float n001 = dot(g001, vec3(Pf0.xy, Pf1.z)); float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
          float n011 = dot(g011, vec3(Pf0.x, Pf1.yz)); float n111 = dot(g111, Pf1);
          vec3 fade_xyz = fade(Pf0); vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
          vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y); return 2.2 * mix(n_yz.x, n_yz.y, fade_xyz.x);
        }
  
        float turbulence(vec3 p) {
          float t = -0.1;
          for (float f = 1.0; f <= 5.0; f++) t += abs(cnoise(p * pow(2.0, f))) / pow(2.0, f);
          return t;
        }
  
        void main() {
          vUv = uv;
          float mouseInfluence = 0.5 * (mouseX + mouseY);
          float hoverFactor = 1.0 + hoverIntensity * 0.5;
          float wave = sin(time * 0.5 + position.x * 2.0 + mouseInfluence) * hoverFactor;
          displacement = turbulence(position * 0.1 + time) * wave;
          vec3 newPosition = position + normal * displacement * 0.2;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(newPosition, 1.0);
          gl_PointSize = pointscale;
        }
      `,
      fragmentShader: `
        varying vec2 vUv;
        varying float displacement;
        uniform float time;

        vec3 getColor(float t) {
          vec3 color1 = vec3(0.0, 0.5, 1.0); // Light blue
          vec3 color2 = vec3(0.0, 0.0, 0.5); // Dark blue
          return mix(color1, color2, t);
        }

        void main() {
          float t = abs(displacement) * 0.5;
          vec3 color = getColor(t);
          float lightIntensity = 0.5 + 0.5 * cos(time * 0.2 + vUv.y * 6.0);
          gl_FragColor = vec4(color * lightIntensity, 1.0);
        }
      `,
      uniforms: {
        time: { value: 0 },
        pointscale: { value: 1.0 },
        decay: { value: 12.0 },
        complex: { value: 17.0 },
        waves: { value: 28.0 },
        eqcolor: { value: 4 },
        mouseX: { value: 0 },
        mouseY: { value: 0 },
        hoverIntensity: { value: 0 }
      },
      transparent: true
    });

    // Create geometry and mesh
    const geometry = new THREE.SphereGeometry(3.9, 128, 128);
    const fluid = new THREE.Mesh(geometry, material);
    scene.add(fluid);
    camera.position.z = 10;

     // Handle resizing
     const handleResize = () => {
      const width = container.clientWidth;
      const height = container.clientHeight;
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
    };

    window.addEventListener('resize', handleResize);

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      material.uniforms.time.value += 0.006;
      fluid.rotation.x += 0.001;
      fluid.rotation.y += 0.001;
      renderer.render(scene, camera);
    };

    animate();

     // Cleanup on component unmount
     return () => {
      window.removeEventListener('resize', handleResize);
      container.removeChild(renderer.domElement);
    };
  }, []);

  return null // No need to return anything since we are rendering to an external element
};

export default FluidSphere;
