import React, { useState, useEffect, useRef } from "react";
import gsap from 'gsap';
import { db } from '../../firebase'; // Corrected import path
import { collection, addDoc } from 'firebase/firestore';
import { serverTimestamp } from "firebase/firestore"; // Import this

import { useNavigate } from 'react-router-dom'; // Import useNavigate

import './Contact.css';
import Header from '../Header/Header';
import FooterMiniMini from '../FooterMiniMini/FooterMiniMini'

const Contact = () => {
  const contactRef = useRef(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [organization, setOrganization] = useState('');
  const [services, setServices] = useState('');
  const [message, setMessage] = useState('');
  const [loader, setLoader] = useState(false); 
  const navigate = useNavigate(); // Initialize useNavigate

  const handleSubmit = async (e) => {
      e.preventDefault();

      if (!name || !email || !message || !organization || !services) {
          alert('Please fill in all fields.');
          return;
      }

      setLoader(true);

      try {
          await addDoc(collection(db, 'contacts'), {
              name: name,
              email: email,
              organization: organization,
              services: services,
              message: message,
              timestamp: serverTimestamp() // Add the timestamp here

          });
          alert('Message has been submitted');
          navigate('/success'); // Redirect to success page
      } catch (error) {
          alert(error.message);
      } finally {
          setLoader(false);
          setName('');
          setEmail('');
          setOrganization('');
          setServices('');
          setMessage('');
      }
  };

  useEffect(() => {
    gsap.set(contactRef.current, { opacity: 1 });
  }, []);

  return (
    <>
      <div className="contact-main-container" ref={contactRef}>
        <Header />
        <div className="contact-container">
          <div className="contact-container-title">
            <section>
              <svg
                className="flecha-icon-contact"
                width="15"
                height="15"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="13" y="45" width="38" height="6" fill="black" />
                <rect
                  x="51"
                  y="13"
                  width="38"
                  height="6"
                  transform="rotate(90 51 13)"
                  fill="black"
                />
                <rect
                  x="4.67915"
                  y="0.436523"
                  width="65.6669"
                  height="6"
                  transform="rotate(45 4.67915 0.436523)"
                  fill="black"
                />
              </svg>
            </section>
            <div className="contact-img-title-container">
              <img  src="https://imagedelivery.net/TAXJk1NwsXQB-lhRumatkg/d174e45c-a398-4747-816a-0123096b7a00/public"className="contact-img" alt="headshot" />
              <h2>Let's start a </h2>
            </div>
            <h2>project together</h2>
          </div>
          <div className="contact-form-details-container">
            <div className="contact-container-form">
              <form className="contact-minimal-form" onSubmit={handleSubmit}>
                <div className="contact-form-group">
                  <label htmlFor="name">What's your name?</label>
                  <input 
                    type="text" 
                    id="name" 
                    name="name" 
                    placeholder="John Doe *"
                    value={name}
                    onChange={(e) => setName(e.target.value)} 
                    required
                  />
                </div>
                <div className="contact-form-group">
                  <label htmlFor="email">What's your email?</label>
                  <input 
                    type="email" 
                    id="email" 
                    name="email" 
                    placeholder="john@doe.com*" 
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="contact-form-group">
                  <label htmlFor="organization">What's the name of your organization?</label>
                  <input 
                    type="text" 
                    id="organization" 
                    name="organization" 
                    placeholder="John & Doe ®"
                    value={organization}
                    onChange={(e) => setOrganization(e.target.value)}
                  />
                </div>
                <div className="contact-form-group">
                  <label htmlFor="services">What services are you looking for?</label>
                  <input 
                    type="text" 
                    id="services" 
                    name="services" 
                    placeholder="Web Design, Web Development ..."
                    required
                    value={services}
                    onChange={(e) => setServices(e.target.value)}
                  />
                </div>
                <div className="contact-form-group">
                  <label htmlFor="message">Your message</label>
                  <textarea 
                    id="message" 
                    name="message" 
                    placeholder="Hello Victor, can you help me with ...*" 
                    required 
                    rows="6" 
                    cols="50"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                <button 
                  type='submit'
                  disabled={loader}
                  className={`contact-submit-button ${loader ? 'loading' : ''}`}
                >
                {loader ? 'Submitting...' : 'Submit'}
                </button>
              </form>
            </div>
            <div className="contact-container-details">
              <div className="contact-detail-container">
                <div className="detail-title">CONTACT DETAILS</div>
                <div className="detail-content">info@victormaragioglio.com</div>
                <div className="detail-content">+49 1523 6786964</div>
              </div>
              <div className="contact-detail-container">
                <div className="detail-title">BUSINESS DETAILS</div>
                <div className="detail-content">Victor Maragioglio</div>
                <div className="detail-content">Location: Germany</div>
              </div>
              <div className="contact-detail-container">
                <div className="detail-title">SOCIALS</div>
                  <a href="https://github.com/maragiogliov" target="_blank" rel="noreferrer" className="detail-content">Github</a>
                  <a href="https://www.behance.net/vmaragioglio" target="_blank" rel="noreferrer" className="detail-content">Behance</a>
                  <a href="https://www.linkedin.com/in/victor-maragioglio-ba3073214/" target="_blank" rel="noreferrer"  className="detail-content">LinkedIn</a>
              </div>
            </div>
          </div>
        </div>
        <FooterMiniMini />
      </div>
    </>
  );
};

export default Contact;
