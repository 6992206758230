import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate from React Router
import './Success.css';
import Header from "../../components/Header/Header";
import FooterMinimal from "../../components/FooterMinimal/FooterMinimal";

const Success = () => {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleBackToContact = () => {
    navigate('/contact'); // Navigate back to the /contact page
  };

  return (
    <>
      <Header/>
      <div className="success-container">
        <div className="success-text-container">
          <div className="success-secondary-text-container">
            <div className="success-secondary-text">I will contact you as soon as possible.</div>
          </div>
          <div className="success-main-text-container">
            <div className="success-main-text">Success</div>
            <div className="success-main-text">Message Sent!</div>
          </div>
        </div>

        {/* Update the button to call the handleBackToContact function */}
        <div className="success-back-to-contact" onClick={handleBackToContact}>
          Back to contact
        </div>
      </div>
      <FooterMinimal/>
    </>
  );
};

export default Success;
