import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

const SimpleAnimation = () => {
  
  useEffect(() => {
    // Access the external DOM element
    const container = document.getElementById("myScene");

    // Scene setup
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(750, container.clientWidth / container.clientHeight, 0.4, 1000);
    const renderer = new THREE.WebGLRenderer();
    container.appendChild(renderer.domElement);

    renderer.setSize(container.clientWidth, container.clientHeight);

  // Cube creation with visible edges
    const geometry = new THREE.BoxGeometry();
    const edges = new THREE.EdgesGeometry(geometry);
    const lineMaterial = new THREE.LineBasicMaterial({ color: 0xffffff }); // White edges
    const cubeEdges = new THREE.LineSegments(edges, lineMaterial);
    scene.add(cubeEdges);



    camera.position.z = 5;

    // Handle resizing
    const handleResize = () => {
      const width = container.clientWidth;
      const height = container.clientHeight;
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
    };

    window.addEventListener('resize', handleResize);

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      cubeEdges.rotation.x += 0.01;
      cubeEdges.rotation.y += 0.01;
      renderer.render(scene, camera);
    };

    animate();

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      container.removeChild(renderer.domElement);
    };
  }, []);

  return null; // No need to return anything since we are rendering to an external element
};

export default SimpleAnimation;
