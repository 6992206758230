import React, { useEffect, useState } from 'react';
import './FooterMinimal.css';

export default function FooterMinimal() {
  const [localTime, setLocalTime] = useState('');

  useEffect(() => {
    const updateLocalTime = () => {
      const date = new Date();
      const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
      setLocalTime(date.toLocaleTimeString([], options));
    };

    // Initial time set
    updateLocalTime();

    // Update time every second
    const intervalId = setInterval(updateLocalTime, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className='footerMinimal-container'>
      <div className="footer-container">
        <div className='footer-bottom-info-container'>
          <div className='footer-version-time-container'>
            <div className='footer-version-container'>
              <div className='footer-title-end'>VERSION</div>
              <div>2024©Edition</div>
            </div>
            <div>
              <div className='footer-title-end'>LOCAL TIME</div>
              <div>{localTime}</div>
            </div>
          </div>
          <div>
            <div className='footer-title-end'>SOCIALS</div>
            <div className='footer-social-container'>
              <a href="https://github.com/maragiogliov" target="_blank"rel="noopener noreferrer">Github</a>
              <a href="https://www.behance.net/vmaragioglio" target="_blank" rel="noopener noreferrer">Behance</a>
              <a href="https://www.linkedin.com/in/victor-maragioglio-ba3073214/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
