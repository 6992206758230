import React, { useState } from 'react';
import './Lab.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SimpleAnimation from './SimpleAnimation';
import FluidSphere from './FluidSphere';

export default function Lab() {
  const [selectedProject, setSelectedProject] = useState(null);



  const projects = [
    { id: 1, name: 'Rotating Cube', content: <SimpleAnimation/> },
    { id: 2, name: 'Fluid Sphere', content: <FluidSphere/> },
    { id: 3, name: 'Project 3', content: 'Details of Project 3' },
    { id: 4, name: 'Project 4', content: 'Details of Project 4' },
    { id: 5, name: 'Project 5', content: 'Details of Project 5' },
    { id: 6, name: 'Project 6', content: 'Details of Project 6' },
    // Add more projects here
  ];

  const handleProjectClick = (project) => {
    setSelectedProject(project);
  };

  return (
    <>
      <Header />
      <div className='lab-container-of-container'>

            <div className="lab-container">
              {/* Left Panel */}
              <div className="lab-left-panel">
                <div className="lab-project-list">
                  {projects.map((project) => (
                    <div 
                    key={project.id} 
                    onClick={() => handleProjectClick(project)}
                    className={`lab-project-item ${selectedProject && selectedProject.id === project.id ? 'active' : ''}`}
                  >
                      {project.name}
                    </div>
                  ))}
                </div>
              </div>

              {/* Right Box */}
              <div className='right-box-container'>

                <div className="lab-right-box" id='myScene'>
                  {selectedProject ? (
                    <div className="lab-project-content">
                      <div>{selectedProject.content}</div>
                    </div>
                  ) : (
                    <p>Select a project to see details</p>
                  )}
                </div>

              </div>
            </div>


      </div>

      <Footer />
    </>
  );
}
