import React from 'react';

const Archive = () => {
  return (
    <div>
      <h1>Archive</h1>
      <p>This is the archive page.</p>
    </div>
  );
}

export default Archive;